import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/Modal';
import {
  formatterPhoneNumber,
  formatterDocumentNumber,
} from '../../../components/Masks';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from '../styles';
import { IViewUserModalProps } from '../types';
import { Button } from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import useGetMe from "../../../hooks/useCurrentUser";



const UnidadeRecebivelModal: React.FC<IViewUserModalProps> = (props: IViewUserModalProps) => {
  const {
    user,
    isVisible,
    onClose,
    doLogout,
    loading,
  } = props;

  const history = useHistory();
  const FormatterDigitoConta = (value: string) => {
    const lastNumber = value.split('')[value.length - 1];
    const number = value.substring(0, value.length - 1);
    if (value.length >= 1) {
      return `${number}-${lastNumber}`;
    }
    return '';
  };

  const handleAccessSupportLink = () => {
    doLogout();
    history.push(`/login/suporte/${user.email}`);
  }

  const actionUser = useGetMe();

  return (
    <Modal
      title="Detalhes do usuário"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <LoadingOverlay
        show={loading}
        relative
      />
      <ModalBody>
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{user.name}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Sobrenome:</Description>
          <Item>{user.lastName}</Item>
        </TextGroup>
        <TextGroup>
          <Description>E-Mail:</Description>
          <Item>{user.email}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Documento:</Description>
          <Item>{formatterDocumentNumber(user.documentNumber)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Telefone:</Description>
          <Item>{formatterPhoneNumber(user.phoneNumber)}</Item>
        </TextGroup>
      </ModalBody>
      {user?.contaExterna
        && (
          <>
            <TextGroup>
              <Description>Tipo Beneficiário:</Description>
              <Item>{user.contaExterna.tipoBeneficiario === 1 ? 'Pessoa física' : 'Pessoa jurídica'}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Tipo Conta:</Description>
              <Item>{user.contaExterna.tipoConta === 1 ? 'Conta Corrente' : 'Conta Poupança'}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Instituição Financeira:</Description>
              <Item>{`${user.contaExterna.instituicaoFinanceira.codigo}-${user.contaExterna.instituicaoFinanceira.nome}`}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Nome do beneficiário:</Description>
              <Item>{user.contaExterna.nomeBeneficiario}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Documento do beneficiário:</Description>
              <Item>{formatterDocumentNumber(user.contaExterna.documentNumber)}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Agência:</Description>
              <Item>{user.contaExterna.numeroAgencia}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Numero da conta:</Description>
              <Item>{FormatterDigitoConta(user.contaExterna.numeroConta)}</Item>
            </TextGroup>
          </>
        )}
      {user?.address
        && (
          <>
            <TextGroup>
              <Description>Cidade:</Description>
              <Item>{user.address.cidade}</Item>
            </TextGroup>
            <TextGroup>
              <Description>UF:</Description>
              <Item>{user.address.uf}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Bairro:</Description>
              <Item>{user.address.bairro}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Logradouro:</Description>
              <Item>{user.address.logradouro}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Número:</Description>
              <Item>{user.address.numero}</Item>
            </TextGroup>
            <TextGroup>
              <Description>Cep:</Description>
              <Item>{user.address.cep}</Item>
            </TextGroup>
          </>
        )}
      {(user.businessList !== undefined && user.businessList.length > 0) && (
        <>
          <br/>
          <h3>Empresa(s) vinculada(s): {user.businessList.length}</h3>
          {user.businessList.map((business) => {
            return (
              <>
                <TextGroup>
                  <Description>Código do lojista:</Description>
                  <Item>{business.merchantId}</Item>
                </TextGroup>
                <TextGroup>
                  <Description>Empresa:</Description>
                  <Item>{business.nickName}</Item>
                </TextGroup>
                {business.cnpj && (
                  <TextGroup>
                    <Description>CNPJ:</Description>
                    <Item>{business.cnpj}</Item>
                  </TextGroup>
                )}
                <br/>
              </>
            )
          })}
        </>
      )}
      {(actionUser.userType === 3 || actionUser.userType === 6 || actionUser.userType === 7) && (
        <Button onClick={handleAccessSupportLink}>Acessar perfil</Button>
      )}
    </Modal>
  );
};

export default UnidadeRecebivelModal;
