import React, {useEffect, useState} from "react";
import {Button, Container} from "./styles";
import {T2} from "../../../styles/titles";
import LiveTable from "./components/liveTable";
import {FormFilter} from "./components/filter";
import {FormValues, TotalsData, TransactionsTableData} from "./types";
import DetalhesEdiServices from "./services/detalhesEdiService";
import {Totals} from "./components/totals";
import LoadingOverlay from "../../../components/LoadingOverlay";

const DetalhesEdi: React.FC = () => {
  const [filterData, setFilterData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totals, setTotals] = useState<TotalsData | null>(null);
  const [tableData, setTableData] = useState<TransactionsTableData | null>(null);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const detalhesEdiService = new DetalhesEdiServices();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await detalhesEdiService.GetEdiTransactions(
        (currentPage - 1) * pageSize,
        pageSize * currentPage,
        filterData);
      setTotals({
        totalGross: data.totalGross,
        totalLiquid: data.totalLiquid,
        totalAcquirer: data.totalAcquirer,
        totalRevenue: data.totalRevenue,
        totalItc: data.totalItc,
        totalAdm: data.totalAdm,
        totalMdr: data.totalMdr,
      });

      setTableData({
        records: data.records || [],
        count: data.count || 0 ,
      });
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filterData) {
      fetchData();
    }
  }, [filterData, currentPage, pageSize]);

  const handleDownload = async (filter?: FormValues) => {
    setIsLoading(true)
    const response = await detalhesEdiService.GetAdiqEdiFileDownload(
      currentPage,
      pageSize,
      filter);

    if (response) {
      const objectUrl = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = 'Edi_Adiq.xlsx'; // Nome do arquivo
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    }
    setIsLoading(false)
  };

  return (
    <Container>
      <LoadingOverlay
        show={isLoading}
        relative
      />
      <T2>EDI Adiq</T2>
      {totals && <Totals data={totals} />}
      <FormFilter getFilters={(data => setFilterData(data))}/>
      <Button
        onClick={() => {
          handleDownload(filterData);
        }}
        style={{
          marginBottom: '25px',
          marginRight: '24px',
          width: '150px',
          alignSelf: 'start'
      }}
      >
        Download
      </Button>
      {tableData && <LiveTable
        data={tableData}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
      />
      }
    </Container>
  )
}

export default DetalhesEdi;
