import {TotalsData} from "../../types";
import {ValueCard} from "./styles";
import {formatMoney} from "../../utils";

export const Totals: React.FC<{ data: TotalsData }> = ({ data }) => {

  const formatTotals = (value:number): string => {
    if (isNaN(value)) value = 0;
    return formatMoney(value);
  }

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor das vendas:</p>
          <p>{formatTotals(data.totalGross)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Liquido:</p>
          <p>{formatTotals(data.totalLiquid)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Recebido Adiq:</p>
          <p>{formatTotals(data.totalAcquirer)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor ITC:</p>
          <p>{formatTotals(data.totalItc)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Valor ADM:</p>
          <p>{formatTotals(data.totalAdm)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Custo Total Mdr:</p>
          <p>{formatTotals(data.totalMdr)}</p>
        </div>

        <div>
          <p style={{fontWeight: 'bolder'}}>Receita:</p>
          <p>{formatTotals(data.totalRevenue)}</p>
        </div>
      </ValueCard>
    </>

  )
}
