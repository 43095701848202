import {
  IAllLiveTransactions,
  PainelVendasFilterProps
} from "../../../../global/dataTransferObjects/transacoesTempoReal";
import axios from "axios";


export default class RecebiveisServices {
  getRecebiveis = async(
    page: number,
    pageSize: number,
    filter?: PainelVendasFilterProps,
    sortBy?: string
  ) => {

    const userInfo: any = localStorage.getItem('userInfo');
    let parsedUserInfo = null;
    if (userInfo) parsedUserInfo = JSON.parse(userInfo);

    let commercialHierId = null;
    if (parsedUserInfo.commercialHierId) commercialHierId = parsedUserInfo.commercialHierId;


    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    let statusPagamento = filter?.statusPagamento;
    if (statusPagamento === 'Pending') statusPagamento = 'PENDING';
    else if (statusPagamento === 'Cancelled') statusPagamento = 'CANCELLED';
    else if (statusPagamento === 'Payed') statusPagamento = 'PAYED';

    const paged = true;
    const dateInicial = filter?.initialDate || null;
    const dateFinal = filter?.finalDate || null;
    const saleDateInicial = filter?.saleInitialDate || null;
    const saleDateFinal = filter?.saleFinalDate || null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName?.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const merchantId = parsedSelectedCompany ? parsedSelectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId?.length > 0 ? filter?.merchantId : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;

    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post('https://link.valori.com.vc:8485/api/recebiveis/filtro', {
      offSet: page,
      PageSize: pageSize,
      SortBy: sortBy,
      InitDate: saleDateInicial,
      FinishDate: saleDateFinal ? `${saleDateFinal} 23:59:59` : saleDateFinal,
      SaleInitDate: dateInicial,
      SaleFinishDate: dateFinal ? `${dateFinal} 23:59:59` : dateFinal,
      TipoPagamento: tipoPagamento,
      Bandeira: bandeira,
      StatusPagamento: statusPagamento,
      BusinessName: businessName,
      AcquirerNsu: acquirerNsu,
      Acquirer: acquirer,
      MerchantId: merchantId,
      OrderNumber: orderNumber,
      Paged: false,
      CommercialHierId: commercialHierId
    },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      }
    )
  }
}
