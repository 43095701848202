import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react';
import FormFilter from './components/filter';
import {
  Container,
  Button,
} from './styles';
import { T2 } from '../../../styles/titles';
import { InitialState, Reducer } from './actions/reducer';
import {getAllLiveTransactions, getAllLiveTransactionsRecebiveis, getFileDownload} from './actions';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../global/dataTransferObjects/transacoesTempoReal';
import LiveTable from './components/liveTable';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate} from "../../../utils/formatDate";
import {getAllVendas} from "../../vendas/actions";
import {CheckboxContainer} from "../../simuladores/styles";

const PainelDeRecebiveis: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize] = useState<number>(20);
  const [page] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>(null);
  const [totals, setTotals] = useState();
  const selectedUser = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) :null;

  const handleFilter = async (filter: PainelVendasFilterProps) => {
    setFilterData(filter);
    getAllLiveTransactionsRecebiveis(page, pageSize, filter)(dispatch);
  };

  const handleDownload = async (filter?: PainelVendasFilterProps) => {
    await getFileDownload(page, pageSize, filter, 'receivables-xls')(dispatch);
  };

  const handleCsvDownload = async (filter?: PainelVendasFilterProps) => {
    await getFileDownload(page, pageSize, filter, 'receivables-csv')(dispatch);
  };

  const handleOfxDownload = async (filter?: PainelVendasFilterProps) => {
    await getFileDownload(page, pageSize, filter, 'receivables-ofx')(dispatch);
  };

  if (state.zeroState) {
    return (
      <ZeroState />
    )
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Painel De Recebíveis</T2>
      <FormFilter totals={totals} onFormSubmit={handleFilter} />
      <div>
        <Button onClick={() => { handleDownload(filterData) }} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download Xlsx</Button>
        <Button onClick={() => { handleCsvDownload(filterData) }} style={{marginBottom: '25px', marginRight: '24px', width: '150px', alignSelf: 'start'}}>Download Csv</Button>
        {/*{(selectedUser?.userType === 2 || selectedUser?.userType === 1) && (*/}
        {/*  <Button onClick={() => { handleOfxDownload(filterData) }} style={{marginBottom: '25px',  width: '150px', alignSelf: 'start'}}>Download Ofx</Button>*/}
        {/*)}*/}

      </div>
      <LiveTable dataFilter={filterData} getTotals={(totals) => setTotals(totals)}/>
    </Container>
  );
};

export default PainelDeRecebiveis;
