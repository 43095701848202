import {getAxiosInstance} from "../../../services/providers/api";
import {FilterData} from "../types";
import {PainelVendasFilterProps} from "../../../global/dataTransferObjects/transacoesTempoReal";
import axios from "axios";

export default class DashboardComercialServices {
  getDashboardTotals = async (filter: FilterData) => {
    const paged = true;
    const pageSize = 100;
    const offSet = 0;
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;

    let status = null;
    if (filter.Status === '0') status = 0;
    else status = filter.Status;

    return getAxiosInstance().post('/transacoes/dashboard-totals', {
      offSet: offSet,
      PageSize: pageSize,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      Status: status,
      Paged: true
    });
  }

  getBusiness = async () => {
    return getAxiosInstance().get('/payments/business', {
      offset: 0,
      pageSize: 5000
    })
  }

  getWhiteLabelVendas = async (
    page: number,
    pageSize: number,
    filter?: any,
    sortBy?: string
  ) => {
    const userInfo: any = localStorage.getItem('userInfo');
    let parsedUserInfo = null;
    if (userInfo) parsedUserInfo = JSON.parse(userInfo);

    let commercialHierId = null;
    if (parsedUserInfo.commercialHierId) commercialHierId = parsedUserInfo.commercialHierId;


    const selectedCompany: string | null = localStorage.getItem('selectedCompany');
    let parsedSelectedCompany = null;
    if (selectedCompany) parsedSelectedCompany = JSON.parse(selectedCompany);

    const paged = true;
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;
    const status = filter?.status !== null && filter?.status !== -1 ? filter?.status : null;
    const bandeira = filter?.bandeira !== undefined && filter?.bandeira?.length > 0 ? filter?.bandeira : null;
    const tipoPagamento = filter?.tipoPagamento !== undefined && filter?.tipoPagamento?.length > 0 ? filter?.tipoPagamento : null;
    const businessName = filter?.businessName !== undefined && filter?.businessName.length > 0 ? filter?.businessName : null;
    const acquirerNsu = filter?.acquirerNsu !== undefined && filter?.acquirerNsu.length > 0 ? filter?.acquirerNsu : null;
    const acquirer = filter?.acquirer !== null && filter?.acquirer !== -1 ? filter?.acquirer : null;
    const merchantId = parsedSelectedCompany ? parsedSelectedCompany.merchantId : filter?.merchantId !== undefined && filter?.merchantId.length > 0 ? filter?.merchantId : null;
    const orderNumber = filter?.orderNumber !== undefined && filter?.orderNumber.length > 0 ? filter?.orderNumber : null;

    const token: any = localStorage.getItem('accessToken');
    let parsedToken = null;
    if (token) parsedToken = JSON.parse(token);

    return axios.post('https://link.valori.com.vc:8485/api/vendas/filtro', {
      offSet: page,
      PageSize: pageSize,
      SortBy: sortBy,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      TipoPagamento: tipoPagamento,
      Bandeira: bandeira,
      Status: status,
      BusinessName: businessName,
      AcquirerNsu: acquirerNsu,
      Acquirer: acquirer,
      MerchantId: merchantId,
      OrderNumber: orderNumber,
      Paged: paged,
      CommercialHierId: commercialHierId
    },
      {
        headers: {
          Authorization: `Bearer ${parsedToken.token}`,
        },
      }
    )
  }
}
