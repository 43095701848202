export const formatBank = (bank: string): string => {

  switch (bank){
    case '237': return 'Bradesco';
    case '104': return 'Caixa Econômica Federal';
    case '033': return 'Santander';
    case '341': return 'Itaú';
    case '001': return 'Banco do Brasil';
    case '756': return 'Sicoob';
    case '329': return 'QI Tech';
    case '529': return 'Pinbank IP';
    case '077': return 'Banco Inter';
    case '260': return 'Nubank';
    case '136': return 'CC Unicred do Brasil';
    case '336': return 'Banco C6';
    case '748': return 'Sicred';
    case '274': return 'BMP';
    case '290': return 'PagBank';
    case '403': return 'Cora';
    case '450': return 'Fitbank';
    case '208': return 'BTG Pactual';
    case '197': return 'Stone Pagamentos S.A.';
    case '380': return 'PicPay';
    case '422': return 'Safra';
    default: return 'Banco não encontrado';
  }
}
